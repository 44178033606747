const jquery = require('jquery')
window.$ = window.jQuery = jquery

const sodaTab = document.getElementById('soda-tab')

const colors = [
  '#db0032', // 'Cherry Pop'
  '#f4436c', // 'Strawberry Basil'
  '#ff8672', // 'Extra Peach'
  '#ff8300', // 'Orange Nectarine'
  '#ffc600', // 'Young Mango'
  '#ffd720', // 'Lemon Verbena'
  '#93d500', // 'Pear Elderflower'
  '#4a9462', // 'Gingery Ale'
  '#006098', // 'Sour Blueberry'
  '#dcc7b7', // 'Toasted Coconut'
  '#ddb5c8', // 'White Grape'
  '#752e4a', // 'Blackberry Jam'
]

class Easing {
  static easeOutCubic(t) {
    return 4 * t * t * t
  }

  static easeInOutCubic(t) {
    if (t < 0.5) {
      return 4 * t * t * t
    } else {
      return (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    }
  }
}

class Point {
  constructor(x, y) {
    this.x = x
    this.y = y
  }
}

class Circle {
  constructor(c, r, color, duration) {
    this.c = c
    this.r = r
    this.color = color
    this.duration = duration
    this.offset = new Point(3, 3)
  }

  updateShadowOffset(lightPoint, maxD) {
    return (this.offset = new Point(
      ((this.c.x - lightPoint.x) / maxD) * 200 + 3,
      ((this.c.y - lightPoint.y) / maxD) * 200 + 3
    ))
  }

  movecenter(p1, p2, startTime, maxD) {
    let t = new Date().getTime() - startTime
    if (t >= this.duration) {
      return
    }
    t /= this.duration
    t = Easing.easeInOutCubic(t)
    this.c.x = p1.x + t * (p2.x - p1.x)
    this.c.y = p1.y + t * (p2.y - p1.y)
    this.updateShadowOffset(p2, maxD)
    return requestAnimationFrame(() => {
      return this.movecenter(p1, p2, startTime, maxD)
    })
  }
}

class Hypnotic {
  constructor(id, numCircles) {
    this.numCircles = numCircles
    this.canvas = document.getElementById(id)
    this.canvas.width = this.canvas.clientWidth
    this.canvas.height = this.canvas.clientHeight
    this.ctx = this.canvas.getContext('2d')
    const diagonal = Math.sqrt(this.canvas.width * this.canvas.width + this.canvas.height * this.canvas.height)

    const step = diagonal / this.numCircles
    const timeStep = 5000 / this.numCircles
    this.circles = []
    for (let i = 1, end = this.numCircles, asc = 1 <= end; asc ? i <= end : i >= end; asc ? i++ : i--) {
      let colorIndex = i % 12

      if (colorIndex === 0) {
        colorIndex = 12
      }

      const color = colors[colorIndex - 1]

      this.circles.push(new Circle(new Point(0, this.canvas.height / 2), step * i, color, timeStep * i))
    }
    this.circles.reverse()
    const self = this
    $('#' + id).mousemove(function (e) {
      const offset = $(this).offset()
      const x = e.pageX - offset.left
      const y = e.pageY - offset.top
      return Array.from(self.circles).map((c) =>
        c.movecenter(c.c, new Point(x, y), new Date().getTime(), Math.max(self.canvas.width, self.canvas.height))
      )
    })
    $('#' + id).on('touchmove', function (e) {
      const touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0]
      return Array.from(self.circles).map((c) =>
        c.movecenter(
          c.c,
          new Point(touch.pageX, touch.pageY),
          new Date().getTime(),
          Math.max(self.canvas.width, self.canvas.height)
        )
      )
    })
    $('#' + id).mouseleave(() => self.recenter())
  }

  recenter() {
    const centerCircle = this.circles[this.circles.length - 1]

    sodaTab.style.width = `${centerCircle.r * 0.8}px`
    sodaTab.style.height = `${centerCircle.r * 0.8}px`

    return Array.from(this.circles).map((c) =>
      c.movecenter(
        c.c,
        new Point(this.canvas.width / 2, this.canvas.height / 2),
        new Date().getTime(),
        Math.max(this.canvas.width, this.canvas.height)
      )
    )
  }

  run() {
    this.draw()
    return this.recenter()
  }

  draw() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

    const centerCircle = this.circles[this.circles.length - 1]

    sodaTab.style.top = `${centerCircle.c.y - (centerCircle.r * 0.8) / 2}px`
    sodaTab.style.left = `${centerCircle.c.x - (centerCircle.r * 0.8) / 2}px`

    for (let c of Array.from(this.circles)) {
      this.ctx.moveTo(c.c.x, c.c.y)
      this.ctx.fillStyle = c.color
      this.ctx.shadowColor = 'rgba(0,0,0,0.2)'
      this.ctx.shadowOffsetX = c.offset.x
      this.ctx.shadowOffsetY = c.offset.y
      this.ctx.beginPath()
      this.ctx.arc(c.c.x, c.c.y, c.r, 0, Math.PI * 2)
      this.ctx.fill()
    }

    return requestAnimationFrame(() => this.draw())
  }
}

const anim = new Hypnotic('fg', 24)
anim.run()
